<template>
  <div>
    <StatusModal
      v-model="isSubmitModalActive"
      status-title="Confirm to Add Customer Profile"
      status-color="#28C76F"
      status-icon="CheckCircleIcon"
      @discard="onDiscard()" />
    <ConfirmModal
      v-model="isConfirmModalActive"
      :items="addItem"
      title="Confirm to Add Customer Profile"
      @confirm="confirmAdd()" />
    <b-card
      @hidden="resetForm()">
      <template>
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="CustomerAddForm">
          <!-- Form -->
          <b-form
            ref="ResetForm"
            class="p-2"
            @submit.prevent="handleSubmit(createCustomerInfo)"
            @reset.prevent="resetForm()">
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.customerId"
                  name="Customer Id"
                  label="Customer Id"
                  placeholder="Input Customer Id"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.name"
                  :rules="{ required: true }"
                  name="Customer Name"
                  label="Customer Name"
                  placeholder="Input Customer Name"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="customer.customerTypeId"
                  :rules="{ required: true }"
                  name="Customer Type"
                  form-label="Customer Type"
                  placeholder="Choose Customer Type"
                  label-option="name"
                  return-value="id"
                  :options="customerType" />
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="customer.customerGroupId"
                  name="Customer Group"
                  form-label="Customer Group"
                  placeholder="Choose Customer Name"
                  label-option="name"
                  return-value="id"
                  :options="customerGroup" />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.sapSoldToCode"
                  name="SAP Sold To Code"
                  label="SAP Sold To Code"
                  placeholder="Input Customer Name"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.sapShipToCode"
                  name="SAP Ship Code"
                  label="SAP Ship Code"
                  placeholder="Input SAP Ship Code"
                  trim />
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.refCode1"
                  name="Ref Code 1"
                  label="Ref Code 1"
                  placeholder="Input Ref Code 1"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.refCode2"
                  name="Ref Code 2"
                  label="Ref Code 2"
                  placeholder="Input Ref Code 2"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.refCode3"
                  name="Ref Code 3"
                  label="Ref Code 3"
                  placeholder="Input Ref Code 3"
                  trim />
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="8">
                <TextFieldInput
                  v-model="customer.companyAddress"
                  name="Company Address"
                  label="Company Address"
                  placeholder="Input Company Address"
                  trim />
              </b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <AddressField
                  v-model="customer.companySubDistrict"
                  label="Company SubDistrict"
                  type="sub-district"
                  @select="onSelectAddress($event)" />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <AddressField
                  v-model="customer.companyDistrict"
                  label="Company District"
                  type="district"
                  @select="onSelectAddress($event)" />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <AddressField
                  v-model="customer.companyProvince"
                  label="Company Province"
                  type="province"
                  @select="onSelectAddress($event)" />
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <AddressField
                  v-model="customer.companyZipCode"
                  label="Company ZipCode"
                  type="postcode"
                  @select="onSelectAddress($event)" />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.companyOfficePhone"
                  name="Company Office Phone"
                  label="Company Office Phone"
                  placeholder="Input Company Office Phone"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.companyMobilePhone"
                  name="Company Mobile Phone"
                  label="Company Mobile Phone"
                  placeholder="Input Company Mobile Phone"
                  trim />
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.ani"
                  name="Ani"
                  label="Ani"
                  placeholder="Input Ani"
                  trim
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.companyEmail"
                  name="Company Email"
                  label="Company Email"
                  placeholder="Input Company Email"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="customer.companyCenterId"
                  name="Company Center"
                  form-label="Company Center"
                  placeholder="Choose Company Center"
                  :options="companyCenter"
                  label-option="name"
                  return-value="id"
                  @input="chooseCompanyCenter($event)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="customer.companyGroupId"
                  name="Company Group"
                  form-label="Company Group"
                  placeholder="Choose Company Group"
                  :options="companyGroup"
                  label-option="name"
                  return-value="id"
                  disabled
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="customer.companyDepartmentId"
                  name="Group"
                  form-label="Company Dapartment"
                  placeholder="Choose Company Department"
                  :options="companyDepartment"
                  label-option="name"
                  return-value="id"
                  disabled
                />
              </b-col>
            </b-row>
            <b-row
            >
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="customer.remark1"
                  :name="`Remark 1`"
                  :label="`Remark 1`" />
              </b-col>
            </b-row>
            <b-row
            >
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="customer.remark2"
                  :name="`Remark 2`"
                  :label="`Remark 2`" />
              </b-col>
            </b-row>
            <b-row
            >
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="customer.remark3"
                  :name="`Remark 3`"
                  :label="`Remark 3`" />
              </b-col>
            </b-row>
            <!-- <b-row>
              <b-col>
                <b-button
                  variant="Light"
                  @click="addRemark()">
                  <feather-icon
                    icon="PlusIcon" />
                  <span class="align-middle">Add Remark</span>
                </b-button>
              </b-col>
            </b-row> -->
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2 btn-submit"
                type="submit">
                Save Changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="btn-submit"
                variant="outline-secondary"
                @click="onDiscard()">
                Discard
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import AddressField from '@/components/Form/AddressField.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'
import ConfirmModal from '@/components/Modal/ConfirmModal.vue'
import StatusModal from '@/components/Modal/StatusModal.vue'
import CustomerProvider from '@/resources/CustomerProvider'
import CustomerGroupProvider from '@/resources/CustomerGroupProvider'
import CustomerTypeProvider from '@/resources/CustomerTypeProvider'
import CompanyGroupProvider from '@/resources/CompanyGroupProvider'
import CompanyDepartmentProvider from '@/resources/CompanyDepartmentProvider'
import CompanyCenterProvider from '@/resources/CompanyCenterProvider'

const CustomerService = new CustomerProvider()
const CustomerGroupService = new CustomerGroupProvider()
const CustomerTypeService = new CustomerTypeProvider()
const CompanyGroupService = new CompanyGroupProvider()
const CompanyDepartmentService = new CompanyDepartmentProvider()
const CompanyCenterService = new CompanyCenterProvider()

export default {
  components: {
    AddressField,
    TextFieldInput,
    SelectField,
    TextAreaInput,
    ConfirmModal,
    StatusModal
  },
  data () {
    return {
      isSubmitModalActive: false,
      isConfirmModalActive: false,
      address: {
        subDistrict: ''
      },
      customer: {
        customerId: '',
        name: '',
        ani: '',
        customerTypeId: '',
        customerGroupId: null,
        sapSoldToCode: '',
        sapShipToCode: '',
        refCode1: '',
        refCode2: '',
        refCode3: '',
        companyAddress: '',
        companySubDistrict: '',
        companyDistrict: '',
        companyProvince: '',
        companyZipCode: '',
        companyOfficePhone: '',
        companyMobilePhone: '',
        companyEmail: '',
        companyGroupId: null,
        companyDepartmentId: null,
        companyCenterId: null,
        remark1: '',
        remark2: '',
        remark3: '',
        queueAgent: 1
      },
      mockData: [
        { label: '#001', value: 0 },
        { label: '#002', value: 1 },
        { label: '#003', value: 2 },
        { label: '#004', value: 3 },
        { label: '#005', value: 4 }
      ],
      mockDataString: [
        { label: '#001', value: '0' },
        { label: '#002', value: '1' },
        { label: '#003', value: '2' },
        { label: '#004', value: '3' },
        { label: '#005', value: '4' }
      ],
      addItem: {},
      customerGroup: [],
      customerType: [],
      companyGroup: [],
      companyDepartment: [],
      companyCenter: []
    }
  },
  computed: {
    customerPayload () {
      const customerPayload = {
        ...this.customer,
        remark1: this.customer.remark[0] || '',
        remark2: this.customer.remark[1] || '',
        remark3: this.customer.remark[2] || ''
      }
      delete customerPayload.remark
      return customerPayload
    }
  },
  created () {
    this.getAll()
  },
  methods: {
    async createCustomerInfo () {
      try {
        const payload = { ...this.customer }
        // payload.ani = this.customer.companyOfficePhone
        payload.queueAgent = 1
        const { data } = await CustomerService.create(this.customer)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Customer Profile Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Customer Profile has been added'
          }
        })
        this.$router.push(`/customer/detail/${data.id}`)
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Customer Profile Record Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `👋 Customer Profile has been failed ${error}`
          }
        })
      }
    },
    async getAll () {
      try {
        const customerGroup = await CustomerGroupService.findAllGroup()
        const customerType = await CustomerTypeService.findAllType()
        const companyGroup = await CompanyGroupService.findAllGroup()
        const companyDepartment = await CompanyDepartmentService.findAllCompanyDepartment()
        const companyCenter = await CompanyCenterService.findAllCompanyCenter()
        this.customerType = customerType.data
        this.customerGroup = customerGroup.data
        this.companyGroup = companyGroup.data
        this.companyDepartment = companyDepartment.data
        this.companyCenter = companyCenter.data
      } catch (error) {
        console.error(error)
      }
    },
    onSubmit () {
      this.openConfirmModal(this.customer)
    },
    onDiscard () {
      this.resetForm()
      this.$router.push('/customer')
    },
    resetForm () {
      this.$refs.ResetForm.reset()
    },
    addRemark () {
      if (this.customer.remark.length < 3) {
        this.customer.remark.push('')
      }
    },
    openConfirmModal (val) {
      this.addItem = {
        'Customer ID :': val.customerId,
        'Customer Name :': val.customerName,
        'Customer Type :': val.customerType.label
      }
      this.isConfirmModalActive = true
    },
    confirmAdd () {
      this.isSubmitModalActive = true
    },
    onSelectAddress (address) {
      this.customer.companySubDistrict = address?.subDistrict || ''
      this.customer.companyDistrict = address?.district || ''
      this.customer.companyProvince = address?.province || ''
      this.customer.companyZipCode = address?.postcode || ''
    },
    chooseCompanyCenter (event) {
      if (event && this.companyCenter && this.companyCenter.length > 0) {
        const { companyDepartmentId, companyGroupId } = this.companyCenter.filter((item) => item.id === event)[0]
        this.customer.companyGroupId = companyGroupId
        this.customer.companyDepartmentId = companyDepartmentId
        return
      }
      this.customer.companyGroupId = null
      this.customer.companyDepartmentId = null
    }
  }
}
</script>
